<template lang="pug">
div
	page-header(:title='title')
	form(@submit.prevent='gravar')
		.modal-body
			.form-horizontal
				vue-element-loading(:active='isActive', spinner='bar-fade-scale', color='#428BCA')
				input-form-text(textInput='Pergunta', :required='true', v-model='form.pergunta', :value='form.pergunta')
				input-form-text(textInput='Resposta', :required='true', v-model='form.resposta', :value='form.resposta')
				.form-group
					select-form(v-model='form.ativo', :value='form.ativo', name='Ativo', url='/arquivos', id='id', titulo='nome', layout='true', optionText='Selecione', required='true')
		footer-button(:disabled='isActive')
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueElementLoading from 'vue-element-loading'
import PageHeader from '@/components/PageHeader'
import InputFormText from '@/components/form/InputForm'
import FooterButton from '@/components/form/FooterButton'
import SelectForm from '@/components/form/SelectForm'
import Permission from '@/mixins/Permission'
import req from '@/components/request'
import { sucesso, erro } from '@/components/Message'

export default {
	components: {
		PageHeader,
		InputFormText,
		FooterButton,
		SelectForm,
		VueElementLoading
	},
	mixins: [Permission],
	computed: {
		...mapGetters([
			'user'
		])
	},
	data() {
		return {
			title: 'Dúvidas',
			form: {
				pergunta: '',
				resposta: '',
				ativo: '1'
			},
			isActive: false
		}
	},
	methods: {
		...mapActions([
			'getLogsTotal'
		]),
		gravar() {
			const body = this.form
			//this.setVersion()
			if (this.$route.params.id) {
				req(`/duvidas/${this.$route.params.id}`, 'put', body).then(() => {
					sucesso('Atualizado...')
					this.getLogsTotal(this.user.id)
					this.$router.push('/sistema/duvidas')
				}).catch(response => {
					console.log('error: ' + response)
					erro()
				})
			} else {
				req('/duvidas', 'post', body).then(() => {
					sucesso('Atualizado...')
					this.getLogsTotal(this.user.id)
					this.$router.push('/sistema/duvidas')
				}).catch(response => {
					console.log('error: ' + response)
					erro()
				})
			}
		},
		async setVersion() {
			const dados = await req('/versao/1', 'put', {
				title: Date.now()
			})
			console.log(dados)
		},
		getDados() {
			req(`/duvidas/${this.$route.params.id}`).then(resp => {
				this.form.ativo = resp.ativo
				this.form.pergunta = resp.pergunta
				this.form.resposta = resp.resposta
				this.isActive = false
			}).catch(response => {
				console.log('error: ' + response)
				erro()
			})
		},
		verifPermissao() {
			if (this.$route.params.id) {
				if (!this.ACTION.e) {
					this.$router.push('/erro')
				}
			} else {
				if (!this.ACTION.c) {
					this.$router.push('/erro')
				}
			}
		}
	},
	mounted() {
		this.verifPermissao()
		if (this.$route.params.id) {
			this.isActive = true
			this.getDados()
		}
	}
}
</script>

<style lang="stylus" scoped>

</style>
