import axios from 'axios'
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(
  'token'
)}`

export default (url, type = 'get', params = null) => {
  return new Promise((resolve, reject) => {
    axios[type](url, params)
      .then(response => {
        resolve(response.data)
      })
      .catch(response => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(response, 'Api call failed!')
      })
  })
}
