import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import router from './router'
import jwt from 'jwt-simple'
import axios from 'axios'
// import swal from 'sweetalert2/dist/sweetalert2.js'
// import 'sweetalert2/src/sweetalert2.scss'

NProgress.configure({ showSpinner: true })

router.beforeEach((to, from, next) => {
  axios.get('/version').then(resp => {
    const version = localStorage.getItem('_version')
    if (!version) {
      localStorage.setItem('_version', resp.data)
      localStorage.setItem('_update', false)
    } else if (version && version !== resp.data) {
      localStorage.setItem('_version', resp.data)
      localStorage.setItem('_update', true)
      /* let timerInterval
      swal({
        title: 'Atualizado!',
        html: 'Aguarde... Redirecionando em <strong></strong> segundos...',
        type: 'success',
        timer: 5000,
        allowOutsideClick: false,
        onBeforeOpen: () => {
          swal.showLoading()
          timerInterval = setInterval(() => {
            swal.getContent().querySelector('strong')
              .textContent = (swal.getTimerLeft() / 1000)
                .toFixed(0)
          }, 100)
        },
        onClose: () => {
          clearInterval(timerInterval)
        }
      }).then(() => {
        window.location.reload()
      }) */
    }
  })
  let user = localStorage.getItem('user')
  if (user) {
    new Promise(resolve => {
      user = JSON.parse(jwt.decode(user, 'admin'))
      resolve()
    }).catch(() => {
      localStorage.clear()
      router.push('/acessar')
    })
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${localStorage.getItem('token')}`
    axios
      .get('/usuarios/ver', {
        params: {
          id: user.id
        }
      })
      .then(resp => {
        if (!resp.data.length) {
          localStorage.clear()
          router.push('/acessar')
        }
      })
  }
  NProgress.start()
  document.title = to.meta.title
  next()
  // NProgress.done()
})

router.afterEach(() => {
  NProgress.done()
})
