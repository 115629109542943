import Vue from 'vue'
import Router from 'vue-router'
import Duvidas from '../pages/Sistema/Duvidas/Router'
Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [
    {
      path: '/redirect',
      component: () => import('@/layouts/Default'),
      hidden: true,
      children: [
        {
          path: '/redirect/:path*',
          component: () => import('@/pages/redirect/index')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/layouts/Default'),
      children: [
        {
          path: '',
          component: () => import('@/pages/Home/Home'),
          name: 'Home',
          meta: {
            pai: 'Home',
            title: 'admiN - Home',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: true,
                url: ''
              }
            ],
            noCache: true
          }
        },

        {
          path: 'inicio/atualizacoes',
          component: () => import('@/pages/Inicio/Atualizacoes'),
          name: 'Atualizacoes',
          meta: {
            path: '/inicio/atualizacoes',
            pai: 'Início',
            title: 'admiN - Atualizações',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Atualizações',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'inicio/atualizacoes/novo',
          component: () => import('@/pages/Inicio/AtualizacoesForm'),
          name: 'AtualizacoesFormNovo',
          meta: {
            path: '/inicio/atualizacoes',
            pai: 'Início',
            title: 'admiN - Atualizações',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Atualizações',
                class: '',
                active: false,
                url: '/inicio/atualizacoes'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'inicio/atualizacoes/:id',
          component: () => import('@/pages/Inicio/AtualizacoesForm'),
          name: 'AtualizacoesFormId',
          meta: {
            path: '/inicio/atualizacoes',
            pai: 'Início',
            title: 'admiN - Atualizações',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Atualizações',
                class: '',
                active: false,
                url: '/inicio/atualizacoes'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'inicio/chamados',
          component: () => import('@/pages/Inicio/Chamados'),
          name: 'Chamados',
          meta: {
            path: '/inicio/chamados',
            pai: 'Início',
            title: 'admiN - Chamados',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Chamados',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'inicio/chamados/novo',
          component: () => import('@/pages/Inicio/ChamadosForm'),
          name: 'ChamadosFormNovo',
          meta: {
            path: '/inicio/chamados',
            pai: 'Início',
            title: 'admiN - Chamados',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Chamados',
                class: '',
                active: false,
                url: '/inicio/chamados'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'inicio/chamados/:id',
          component: () => import('@/pages/Inicio/ChamadosForm'),
          name: 'ChamadosFormId',
          meta: {
            path: '/inicio/chamados',
            pai: 'Início',
            title: 'admiN - Chamados',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Chamados',
                class: '',
                active: false,
                url: '/inicio/chamados'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'inicio/minha-conta',
          component: () => import('@/pages/Inicio/MinhaConta'),
          name: 'MinhaConta',
          meta: {
            path: '/inicio/minha-conta',
            pai: 'Início',
            title: 'admiN - Minha Conta',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Minha Conta',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'admin/usuarios',
          component: () => import('@/pages/Admin/Usuarios'),
          name: 'Usuarios',
          meta: {
            path: '/admin/usuarios',
            pai: 'Admin',
            title: 'admiN - Usuários',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Usuários',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'admin/usuarios/novo',
          component: () => import('@/pages/Admin/UsuariosForm'),
          name: 'UsuariosFormNovo',
          meta: {
            path: '/admin/usuarios',
            pai: 'Admin',
            title: 'admiN - Usuários',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Usuários',
                class: '',
                active: false,
                url: '/admin/usuarios'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'admin/usuarios/:id',
          component: () => import('@/pages/Admin/UsuariosForm'),
          name: 'UsuariosFormId',
          meta: {
            path: '/admin/usuarios',
            pai: 'Admin',
            title: 'admiN - Usuários',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Usuários',
                class: '',
                active: false,
                url: '/admin/usuarios'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },

        {
          path: 'admin/grupo-de-acesso',
          component: () => import('@/pages/Admin/GrupoDeAcesso'),
          name: 'GrupoDeAcesso',
          meta: {
            path: '/admin/grupo-de-acesso',
            pai: 'Admin',
            title: 'admiN - Grupo de Acesso',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Grupo de Acesso',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'admin/grupo-de-acesso/novo',
          component: () => import('@/pages/Admin/GrupoDeAcessoForm'),
          name: 'GrupoDeAcessoFormNovo',
          meta: {
            path: '/admin/grupo-de-acesso',
            pai: 'Admin',
            title: 'admiN - Grupo de Acesso',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Grupo de Acesso',
                class: '',
                active: false,
                url: '/admin/grupo-de-acesso'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'admin/grupo-de-acesso/:id',
          component: () => import('@/pages/Admin/GrupoDeAcessoForm'),
          name: 'GrupoDeAcessoFormId',
          meta: {
            path: '/admin/grupo-de-acesso',
            pai: 'Admin',
            title: 'admiN - Grupo de Acesso',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Grupo de Acesso',
                class: '',
                active: false,
                url: '/admin/grupo-de-acesso'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/assine',
          component: () => import('@/pages/Sistema/Assine'),
          name: 'Assine',
          meta: {
            path: '/sistema/assine',
            pai: 'Sistema',
            title: 'admiN - Assine',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Assine',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/Assine/novo',
          component: () => import('@/pages/Sistema/AssineForm'),
          name: 'AssineFormNovo',
          meta: {
            path: '/sistema/assine',
            pai: 'Sistema',
            title: 'admiN - Assine',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Assine',
                class: '',
                active: false,
                url: '/sistema/assine'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/assine/:id',
          component: () => import('@/pages/Sistema/AssineForm'),
          name: 'AssineEditar',
          meta: {
            path: '/sistema/assine',
            pai: 'Sistema',
            title: 'Admin - Assine',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Assine',
                class: '',
                active: false,
                url: '/sistema/assine'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'sistema/cobertura',
          component: () => import('@/pages/Sistema/Cobertura'),
          name: 'Cobertura',
          meta: {
            path: '/sistema/cobertura',
            pai: 'Sistema',
            title: 'admiN - Cobertura',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'cobertura',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/cobertura/novo',
          component: () => import('@/pages/Sistema/CoberturaForm'),
          name: 'CoberturaFormNovo',
          meta: {
            path: '/sistema/cobertura',
            pai: 'Sistema',
            title: 'admiN - Cobertura',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Cobertura',
                class: '',
                active: false,
                url: '/sistema/cobertura'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/cobertura/:id',
          component: () => import('@/pages/Sistema/CoberturaForm'),
          name: 'CoberturaEditar',
          meta: {
            path: '/sistema/cobertura',
            pai: 'Sistema',
            title: 'Admin - Cobertura',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Cobertura',
                class: '',
                active: false,
                url: '/sistema/cobertura'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'sistema/contato',
          component: () => import('@/pages/Sistema/Contato'),
          name: 'Contato',
          meta: {
            path: '/sistema/contato',
            pai: 'Sistema',
            title: 'admiN - Contato',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Contato',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/Contato/novo',
          component: () => import('@/pages/Sistema/ContatoForm'),
          name: 'ContatoFormNovo',
          meta: {
            path: '/sistema/contato',
            pai: 'Sistema',
            title: 'admiN - Contato',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Contato',
                class: '',
                active: false,
                url: '/sistema/contato'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/contato/:id',
          component: () => import('@/pages/Sistema/ContatoForm'),
          name: 'ContatoEditar',
          meta: {
            path: '/sistema/contato',
            pai: 'Sistema',
            title: 'Admin - Contato',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Contato',
                class: '',
                active: false,
                url: '/sistema/contato'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'sistema/destaque',
          component: () => import('@/pages/Sistema/Destaque'),
          name: 'Destaque',
          meta: {
            path: '/sistema/destaque',
            pai: 'Sistema',
            title: 'admiN - Destaque',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Destaque',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/Destaque/novo',
          component: () => import('@/pages/Sistema/DestaqueForm'),
          name: 'DestaqueFormNovo',
          meta: {
            path: '/sistema/destaque',
            pai: 'Sistema',
            title: 'admiN - Destaque',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Destaque',
                class: '',
                active: false,
                url: '/sistema/assine'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/destaque/:id',
          component: () => import('@/pages/Sistema/DestaqueForm'),
          name: 'DestaqueEditar',
          meta: {
            path: '/sistema/destaque',
            pai: 'Sistema',
            title: 'Admin - Destaque',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Destaque',
                class: '',
                active: false,
                url: '/sistema/destaque'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'sistema/planos',
          component: () => import('@/pages/Sistema/Planos'),
          name: 'Planos',
          meta: {
            path: '/sistema/planos',
            pai: 'Sistema',
            title: 'admiN - Planos',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Planos',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/Planos/novo',
          component: () => import('@/pages/Sistema/PlanosForm'),
          name: 'PlanosFormNovo',
          meta: {
            path: '/sistema/planos',
            pai: 'Sistema',
            title: 'admiN - Planos',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Planos',
                class: '',
                active: false,
                url: '/sistema/planos'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/planos/:id',
          component: () => import('@/pages/Sistema/PlanosForm'),
          name: 'PlanosEditar',
          meta: {
            path: '/sistema/planos',
            pai: 'Sistema',
            title: 'Admin - Planos',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Planos',
                class: '',
                active: false,
                url: '/sistema/planos'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'admin/status',
          component: () => import('@/pages/Admin/Status'),
          name: 'Status',
          meta: {
            path: '/admin/status',
            pai: 'Admin',
            title: 'admiN - Status',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Status',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'admin/status/novo',
          component: () => import('@/pages/Admin/StatusForm'),
          name: 'StatusFormNovo',
          meta: {
            path: '/admin/status',
            pai: 'Admin',
            title: 'admiN - Status',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Status',
                class: '',
                active: false,
                url: '/admin/status'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'admin/status/:id',
          component: () => import('@/pages/Admin/StatusForm'),
          name: 'StatusFormId',
          meta: {
            path: '/admin/status',
            pai: 'Admin',
            title: 'admiN - Status',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Status',
                class: '',
                active: false,
                url: '/admin/status'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },

        {
          path: 'logs/logs',
          component: () => import('@/pages/Logs/Logs'),
          name: 'Logs',
          meta: {
            path: '/logs/logs',
            pai: 'Logs',
            title: 'admiN - Logs',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Logs',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        ...Duvidas,
      ]
    },
    {
      path: '/acessar',
      component: () => import('@/layouts/Login'),
      children: [
        {
          path: '',
          component: () => import('@/pages/Login/Acessar'),
          name: 'Acessar',
          meta: {
            title: 'admiN - Acessar',
            noCache: true
          }
        }
      ]
    },
    {
      path: '/imprimir',
      component: () => import('@/layouts/Imprimir'),
      children: [
        {
          path: 'recibo/:id',
          component: () => import('@/pages/Imprimir/Recibo'),
          name: 'Recibo',
          meta: {
            title: 'admiN - Acessar',
            noCache: true
          }
        },
        {
          path: 'contrato/:id',
          component: () => import('@/pages/Imprimir/Contrato'),
          name: 'Contrato',
          meta: {
            title: 'admiN - Acessar',
            noCache: true
          }
        }
      ]
    },
    {
      path: '*',
      component: () => import('@/layouts/Default'),
      children: [
        {
          path: '',
          component: () => import('@/pages/Erro/404'),
          name: '404',
          meta: {
            pai: 'Admin',
            title: 'admiN - Erro',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Erro 404',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        }
      ]
    }
  ]
})
