import swal from 'sweetalert2/dist/sweetalert2.js'

export const sucesso = msg => {
  return swal.fire({
    title: 'Sucesso!',
    text: msg,
    type: 'success',
    timer: 2000
  })
}

export const erro = () => {
  return swal.fire({
    title: 'Erro!',
    text: 'Aconteceu algum erro!',
    type: 'error'
  })
}
