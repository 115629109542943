<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="stylus">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#nprogress .bar {
  background: #fff !important;
}
#nprogress .spinner-icon {
  border-top-color: #fff;
  border-left-color: #fff;
}
.k-pager-wrap .k-state-selected:hover {
    color: #fff;
    background-color: #438eb9 !important;
    cursor: default;
}
.k-pager-wrap .k-state-selected {
    color: #fff;
    background-color: #438eb9 !important;
}
.k-pager-numbers .k-link, .k-pager-numbers .k-link:link {
    color: #438eb9 !important;
}

.swal2-container {
  z-index: 9999;
}
.grid-clientes.k-grid.k-widget.k-display-block.k-grid-lockedcolumns>div.k-grid-content.k-auto-scrollable>table>tbody>tr {
	height 50px
}

.k-current-page {
	display: none !important;
}

.k-state-selected {
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}

td {
	white-space: nowrap;
}
</style>
