import Vue from 'vue'

const template = {
  methods: {
    commandTemplate(e) {
      var vm = this
      return {
        template: Vue.component('temp', {
          template: `<div class='text-center'>
              <a :href='url(templateArgs.id)' class='btn btn-warning btn-sm btn-editar' title='Editar' @click.prevent='buttonClick(templateArgs.id)'>
                <i class='fa fa-pencil-square-o'></i> Editar
              </a>
             </div>`,
          data() {
            return {
              templateArgs: {}
            }
          },
          methods: {
            url(id) {
              return `${vm.url}${id}`
            },
            buttonClick(id) {
              vm.$router.push(`${vm.url}${id}`)
            }
          }
        }),
        templateArgs: e
      }
    }
  }
}

export default template
