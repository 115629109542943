<template>
	<div>
		<page-header :title="title" :url="url" :titleNew="titleNew"></page-header>
		<grid ref="grid" :url="urlDataSource" :source="source" :columns="columns" :grid="grid"></grid>
	</div>
</template>
  
<script>
import swal from '@/utils/swal'
import Services from '@/utils/services'
import PageHeader from '@/components/PageHeader'
import Grid from '@/components/Grid'

import GridTemplate from '@/mixins/GridTemplate'
import Permission from '@/mixins/Permission'

export default {
	mixins: [GridTemplate, Permission],
	components: {
		PageHeader,
		Grid
	},
	computed: {
		source() {
			return [
				{
					serverFiltering: true,
					pageSize: 8,
					serverPaging: true,
					schemaData: 'data',
					schemaTotal: 'total',
					serverSorting: true,
					schemaParse: this.parse
				}
			]
		}
	},
	data() {
		return {
			title: 'Dúvidas',
			titleNew: '',
			url: '/sistema/duvidas/',
			urlDataSource: `${this.$url}/duvidas/all`,
			columns: [
				{
					field: 'pergunta',
					title: 'Pergunta',
					width: 200
				},
				{
					field: 'ativo',
					title: 'Ativo',
					width: 120
				}
			],
			grid: [
				{
					height: 500,
					navigatable: true,
					columnMenu: true,
					sortable: false,
					pageable: true,
					pageablePageSizes: [15, 50, 75, 100],
					pageableAlwaysVisible: true
				}
			]
		}
	},
	methods: {
		parse(d) {
			d.data.map(i => {
				i.created_at = new Date(i.created_at)
				return i
			})
			return d
		},
		async getApagar(id) {
			const dialog = await swal.dialog(
				'Excluir',
				'Deseja excluir esse registro?'
			)
			if (dialog.value) {
				await Services.delete(`/perguntas/${id}`)
				swal.success('Excluido!', 3000)
				this.$refs.grid.$refs.datasource1.kendoDataSource.read()
			}
		},
		verifPermissao() {
			this.titleNew = this.ACTION.c ? 'Novo' : ''
			if (this.ACTION.e) {
				this.columns.push({
					field: 'ativo',
					title: 'Ações',
					width: 105,
					template: this.commandTemplate
				})
			}
		}
	},
	created() {
		this.verifPermissao()
	}
}
</script>
  
<style lang="scss">
  .grid-agenda.k-grid.k-widget.k-display-block.k-grid-lockedcolumns > div.k-grid-content.k-auto-scrollable > table > tbody > tr {
	height: 73px;
  }
  .k-current-page {
	display: none !important;
  }
  
  .k-state-selected {
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  </style>
  