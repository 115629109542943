import Base from '../Pages/Base'
import List from '../Pages/List'
import Form from '../Pages/Form'

export default [
	{
		path: 'sistema/duvidas',
		component: Base,
		children: [
			{
				path: '',
				name: 'DuvidasList',
				component: List,
				meta: {
					path: '/sistema/duvidas',
					pai: 'Sistema',
					title: 'siS - Dúvidas',
					navigation: [{
						pagina: 'Home',
						class: 'ace-icon fa fa-home home-icon',
						active: false,
						url: '/'
					},
					{
						pagina: 'Dúvidas',
						class: '',
						active: true,
						url: false
					}],
					noCache: true
				}
			},
			{
				path: 'novo',
				name: 'DuvidasNovo',
				component: Form,
				meta: {
					path: '/sistema/duvidas',
					pai: 'Sistema',
					title: 'siS - Dúvidas - Novo',
					navigation: [{
						pagina: 'Home',
						class: 'ace-icon fa fa-home home-icon',
						active: false,
						url: '/'
					},
					{
						pagina: 'Dúvidas',
						class: '',
						active: false,
						url: '/sistema/duvidas'
					},
					{
						pagina: 'Novo',
						class: '',
						active: true,
						url: false
					}],
					noCache: true
				}
			},
			{
				path: ':id',
				name: 'DuvidasEditar',
				component: Form,
				meta: {
					path: '/sistema/duvidas',
					pai: 'Sistema',
					title: 'siS - Dúvidas - Editar',
					navigation: [{
						pagina: 'Home',
						class: 'ace-icon fa fa-home home-icon',
						active: false,
						url: '/'
					},
					{
						pagina: 'Dúvidas',
						class: '',
						active: false,
						url: '/sistema/duvidas'
					},
					{
						pagina: 'Editar',
						class: '',
						active: true,
						url: false
					}],
					noCache: true
				}
			}
		]
	}
]