// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import store from './store'
import axios from 'axios'
import swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import VueAutosize from 'vue-autosize'
import App from './App'
import router from './router'
import { sync } from 'vuex-router-sync'
import vueDebounce from 'vue-debounce'
import './permission'
import './assets/tailwind.css'
sync(store, router)
window.$ = window.jQuery = require('jquery')
const url =
  window.location.hostname === 'localhost'
    ? 'http://localhost:8000'
    : 'https://api.topnetms.com.br'

Vue.prototype.$url = url

Vue.config.productionTip = false

axios.defaults.baseURL = url
if (localStorage.getItem('token')) {
  new Promise(resolve => {
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${localStorage.getItem('token')}`
    resolve()
  }).catch(() => {
    localStorage.clear()
    window.location = '/acessar'
  })
}
Vue.prototype.$axios = axios
Vue.prototype.$swal = swal
Vue.use(VueAutosize)
Vue.use(vueDebounce)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
