<template lang="pug">
div
  kendo-datasource(ref="datasource1",
    :transport-read="{ url, beforeSend: onBeforeSend }",
    v-bind='datasource'
  )
  kendo-grid(:data-source-ref="'datasource1'", v-bind="grid")
    kendo-grid-column(v-for='(column, i) in columns',
      :key='i',
      v-bind='column')
</template>

<script>
import Vue from 'vue'
import '@progress/kendo-ui'
import '@progress/kendo-theme-default/dist/all.css'
import '@progress/kendo-ui/js/cultures/kendo.culture.pt-BR'
import '@progress/kendo-ui/js/messages/kendo.messages.pt-BR'

import { Grid, GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import {
  DataSource,
  DataSourceInstaller
} from '@progress/kendo-datasource-vue-wrapper'

/* :filter='filtro',
    :server-filtering='!!filter' */

Vue.use(GridInstaller)
Vue.use(DataSourceInstaller)
// eslint-disable-next-line no-undef
kendo.culture('pt-BR')

export default {
  name: 'Grid',
  props: ['url', 'columns', 'source', 'grid'],
  components: {
    Grid,
    DataSource
  },
  computed: {
    datasource() {
      return this.source
    }
  },
  methods: {
    onBeforeSend(xhr) {
      xhr.setRequestHeader(
        'Authorization',
        'Bearer ' + localStorage.getItem('token')
      )
    }
  },
  mounted() {
    // this.$refs.datasource1.kendoDataSource.read()
  }
}
</script>

<style lang="stylus" scoped>
a.k-header-column-menu {
  z-index: 0 !important;
}
</style>
