<template lang="pug">
.modal-footer
  a.btn.btn-default(type='button', href='javascript:history.back()') Voltar
  button.btn.btn-primary(type='submit', :disabled='disabled') Gravar
</template>

<script>
export default {
  name: 'FooterButton',
  props: ['disabled']
}
</script>

<style lang="stylus" scoped></style>
