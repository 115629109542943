<template lang="pug">
.form-group
  label.col-sm-3.control-label.no-padding-right {{ textInput }}
  .col-sm-9
    input.col-xs-10.col-sm-8(:type='type',
      :required='required',
      :placeholder='textInput',
      v-model='content',
      @input='handleInput',
      :disabled='disabled')
</template>

<script>
export default {
  name: 'InputForm',
  props: {
    value: {
      default: '',
      type: String,
      required: true
    },
    textInput: {
      default: '',
      type: String,
      required: true
    },
    required: {
      default: false,
      type: Boolean,
      required: false
    },
    type: {
      default: 'text',
      type: String,
      required: false
    },
    disabled: {
      default: false
    }
  },
  data() {
    return {
      content: this.value
    }
  },
  watch: {
    value() {
      this.content = this.value
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.content)
    }
  }
}
</script>

<style lang="stylus" scoped></style>
