<template lang="pug">
div
  .page-header
    .row
      .col-md-6
        h1 {{ title }}
      .col-md-5.col-md-offset-1
        .text-right(v-if='titleNew')
          router-link(:to='`${url}novo`').btn.btn-success {{ titleNew }}
  br
</template>

<script>
export default {
  name: 'PageHeader',
  props: ['title', 'titleNew', 'url']
}
</script>

<style lang="stylus" scoped></style>
