import { mapGetters } from 'vuex'
import { permissao, verifica } from '@/components/getMenu.js'

export default {
	computed: {
		...mapGetters([
			'menu'
		])
	},
	data() {
		return {
			PERMISSION: [],
			ACTION: {
				c: false,
				e: false,
				d: false,
				v: false
			}
		}
	},
	methods: {
		getAcesso(acesso) {
			if (!acesso.length) {
				this.$router.push('/')
			} else {
				this.PERMISSION = acesso.map(i => i.submenu)[0].map(i => i.actions)[0]
			}
			this.getPermission()
		},
		getPermission() {
			this.ACTION.c = verifica(this.PERMISSION, 'Cadastrar')
			this.ACTION.e = verifica(this.PERMISSION, 'Editar')
			this.ACTION.d = verifica(this.PERMISSION, 'Apagar')
			this.ACTION.v = verifica(this.PERMISSION, 'Visualizar')
		}
	},
	created() {
		let dados = JSON.parse(localStorage.getItem('_menu'))
		const acesso = permissao(dados, this.$route.meta.pai, this.$route.meta.path)
		this.getAcesso(acesso)
	}
}
